// startup
$theme-colors: (
  primary: #2595FF,
  secondary: #fff,
  success: #5cb85c,
  info: #5bc0de,
  warning: #f0ad4e,
  danger: #d9534f,
  light: #f5f8fa,
  dark: #122a38
);

@import "./variables.scss";

// text overrides
$body-color: #6c6f73;
$font-size-base: 15px;
$font-family-sans-serif: "Work sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;
$text-color: #6C6F73;
$headings-color: #2D3238;
$line-height-base: 1.4;


// gray override
$gray-light: #BEC8CE;
$text-muted: $gray-light;


// h3 override
$font-size-h3: 28px;


// nav tranpsarent
$navbar-transparent-brand-hover-bg:       transparent;
$navbar-transparent-toggler-hover-bg:     transparent;
$navbar-transparent-toggler-border-color: transparent;


// nav bordered
$nav-bordered-color:              #000;
$nav-bordered-color-active:       theme-color("primary");
$nav-bordered-font-weight:        300;
$nav-bordered-font-weight-active: 700;


// misc typography
$headings-font-weight: 500;
$btn-font-weight:      400;
$letter-spacing-base:  -.02em;


// lead
$lead-letter-spacing: $letter-spacing-base;
$lead-font-size:      $font-size-base * 1.5;


// blocks
$block-title-font-size-sm: $font-size-base * 2.8;
$block-title-font-size:    $font-size-base * 2.5;
$block-lead-font-size-sm:  $font-size-base * 1.4;
$block-vertical-padding:   100px;


@import "./components.scss";
